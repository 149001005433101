// This file contains variables for different themes

export const light = {
    body: '#fff',
    text: '#202020',
    bodyRgba: '255, 255, 255',
    textRgba: '32,32,32',

    carouselColor: '#EEEDDE',

    fontxs: '0.75em',
    fontsm: '0.875em',
    fontmd: '1em', // 1em = 16px
    fontlg: '1.25em',
    fontxl: '2em',
    fontxxl: '3em',
    fontxxxl: '4em',

    fontButton: '0.875em',

    navHeight: '5rem',
    mode:'light',
    bodyMode:'orange',
    colorMode: 'white'
};

export const dark = {
    body: '#202020',
    text: '#fff',
    bodyRgba: '32,32,32',
    textRgba: '255, 255, 255',

    carouselColor: '#EEEDDE',

    fontxs: '0.75em',
    fontsm: '0.875em',
    fontmd: '1em', // 1em = 16px
    fontlg: '1.25em',
    fontxl: '2em',
    fontxxl: '3em',
    fontxxxl: '4em',

    fontButton: '0.875em',

    navHeight: '5rem',
    mode:'dark',
    bodyMode:'lightblue',
    colorMode: 'black'

};
